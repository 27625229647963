export interface UserProductInterface {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  status: number;
  isDeleted: boolean;
  role: number;
  caliberUserId: number;
  refreshToken: string;
  caliberUser: {
    pmcId: number;
    userId: number;
    dbConnection: string;
  };
  fscUser: {
    fscPmcId: number;
    fscUserId: number;
    fscEmail: string;
  };
  dwellingUser: {
    dwellingUserAccountId: number;
    dwellingEmail: string;
  };
  paymentsUser: {
    email: string;
    mcId: number;
    userId: number;
    roles: UserRole[];
  };
}


export enum UserRole
{
  OnboardUser,
  FullAccess,
  ReportsUser,
  DashboardUser,
}
