import React from "react";
interface LeafProps {
  attributes: any;
  children: any;
  leaf: any;
}
export default class Leaf extends React.Component<LeafProps> {
  render() {
    let {attributes, children, leaf} = this.props;
    if (leaf.bold) {
      children = <strong>{children}</strong>
    }
    
    if (leaf.code) {
      children = <code>{children}</code>
    }
    
    if (leaf.italic) {
      children = <em>{children}</em>
    }
    
    if (leaf.underline) {
      children = <u>{children}</u>
    }
    
    return <span {...attributes}>{children}</span>
  }
}
