import React from 'react';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import {Button, Dialog, DialogContent, Grid, Menu, MenuItem,} from '@material-ui/core';
import Logo from './Logo';
import {useAuth0} from "../Auth";
import {config} from "../config";
import {connect, ConnectedProps} from "react-redux";
import {ApplicationState} from "../store";
import history from "../utils/history";
import {Dispatch} from "redux";
import * as homeownersActions from "../actions/homeowners";
import * as transactionsActions from "../actions/paymentTransactions";
import moment from "moment";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {withStyles} from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {DropdownItem} from "reactstrap";
import {updateDashboardUserFilter} from "../actions/dashboardUser";
import {SendPaymentsTo} from "../interfaces/user.company.interface";


const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1
        },
        header: {
            height: "80px",
            backgroundColor: "#212B3D",
            boxShadow: "0 4px 6px 0 rgba(0,0,0,0.2)"
        },
        toolbar: {
            height: "80px",
            paddingLeft: "0"
        },
        caption: {
            fontSize: "12px"
        },
        menuButton: {
          textTransform: "capitalize",
          backgroundColor: "#212B3D !important",
          color: "#ffffff !important"
        },
        menuContainer: {
            display: "flex",
            height: "80px",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 20px",
            minWidth: "200px",
            width: "100%",
            borderLeft: "1px solid rgba(255, 255, 255, 0.3)",
            borderRight: "1px solid rgba(255, 255, 255, 0.3)"
        },
        menuSelect: {
            backgroundColor: "#212B3D !important",
            color: "#ffffff !important",
            "&:hover": {
              color: "#2c8ced !important"
            }
        },
        title: {
            flexGrow: 1,
            fontSize: "16px",
            fontWeight: 500,
            letterSpacing: 0
        },
    }),
);

const CustomMenu = withStyles({
  paper: {
    border: '1px solid #3f536e',
    backgroundColor: "#212B3D !important",
    color: "#ffffff !important",
    margin: "10px",
    minWidth: "280px",
    zIndex: 99
  },
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const NavMenu = (props: NavMenuProps) => {
  const classes = useStyles();
  const { userInfo, location, clearFiltersData } = props
  const { isAuthenticated, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [helpEL, setHelpEL] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const emails = config.teamEmails?.toLowerCase().split(',') || [];
  let menu = [];
  
  console.log(process.env);
  
  if (!!userInfo && isAuthenticated) {
    
    if (userInfo?.isFSEmployee) {
      menu.push({
        label: "Payments",
        path: "/Payments"
      });
      menu.push({
        label: "Homeowners",
        path: "/Homeowners"
      });
      menu.push({
        label: "Payouts",
        path: "/Payouts"
      });
      menu.push({
        label: "View Dashboard Users",
        path: "/DashboardUser"
      });
      
      if (!userInfo.userCompany || SendPaymentsTo.LockBoxFile === userInfo?.userCompany?.sendPaymentsTo) {
        menu.push({
          label: "Lockbox Files",
          path: "/LockboxFiles"
        });
      }
      
      if (!userInfo.userCompany) {
        menu.push({
          label: "Associations onboarding",
          path: "/Onboarding"
        });
        
        menu.push({
          label: "Representative Profile",
          path: "/Representative"
        });
      }
      
      menu.push({
        label: "Tax Forms",
        path: "/TaxForms"
      });
      
      menu.push({
        label: "Reports",
        path: "/Reports"
      });
      
      if (emails.includes(userInfo?.email?.toLowerCase() || '')) {
        menu.push({
          label: "Release Notes",
          path: "/Admin"
        });
      }
    
    } else if (userInfo.fullAccessProfile) {
      menu.push({
        label: "Payments",
        path: "/Payments"
      });
      menu.push({
        label: "Homeowners",
        path: "/Homeowners"
      });
      menu.push({
        label: "Payouts",
        path: "/Payouts"
      });
      menu.push({
        label: "View Dashboard Users",
        path: "/DashboardUser"
      });
      if (!userInfo.userCompany || SendPaymentsTo.LockBoxFile === userInfo?.userCompany?.sendPaymentsTo) {
        menu.push({
          label: "Lockbox Files",
          path: "/LockboxFiles"
        });
      }
      menu.push({
        label: "Associations onboarding",
        path: "/Onboarding"
      });
      menu.push({
        label: "Tax Forms",
        path: "/TaxForms"
      });
    } else {
      
      // Adding conditions for the new role implementation
      // Dashboard User
      if (userInfo.isDashboardUser || (
        !userInfo.isOnboardUser
        && !userInfo.isReportsUser
        && !userInfo.isDashboardUser
        && !userInfo.fullAccessProfile
      )) {
        
        menu.push({
          label: "Payments",
          path: "/Payments"
        });
        menu.push({
          label: "Homeowners",
          path: "/Homeowners"
        });
        menu.push({
          label: "Payouts",
          path: "/Payouts"
        });
        menu.push({
          label: "View Dashboard Users",
          path: "/DashboardUser"
        });
        if (!userInfo.userCompany || SendPaymentsTo.LockBoxFile === userInfo?.userCompany?.sendPaymentsTo) {
          menu.push({
            label: "Lockbox Files",
            path: "/LockboxFiles"
          });
        }
        menu.push({
          label: "Tax Forms",
          path: "/TaxForms"
        });
      }
      
      if (userInfo.isOnboardUser || userInfo.fullAccessProfile) {
        menu.push({
          label: "Associations onboarding",
          path: "/Onboarding"
        });
      }
      
      if (userInfo.isReportsUser) {
        menu.push({
          label: "Reports",
          path: "/Reports"
        });
      }
      
      
      // Only Onboarding access
      if (userInfo.isOnboardUser
        && !userInfo.fullAccessProfile
        && !userInfo.isReportsUser
        && !userInfo.isDashboardUser) {
        menu = [];
        menu.push({
          label: "Associations onboarding",
          path: "/Onboarding"
        });
        menu.push({
          label: "Tax Forms",
          path: "/TaxForms"
        });
      }
      
      // Only Reports access
      if (userInfo.globalReportsEnabled) {
        if (userInfo.isReportsUser
          && userInfo.userCompany?.reportsEnabled
          && !userInfo.isOnboardUser
          && !userInfo.isDashboardUser
          && !userInfo.fullAccessProfile
        ) {
          menu = [];
          menu.push({
            label: "Reports",
            path: "/Reports"
          });
        } else if (userInfo.isFSEmployee) {
          menu.push({
            label: "Reports",
            path: "/Reports"
          });
        }
      }
    }
  } else {
    menu.push({
      label: "Representative Profile",
      path: "/Profile"
    });
  }
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };
  
  const onChangeMenuItem = (path: string) => {
    clearFiltersData()
    history.push(path)
    setAnchorEl(null)
  }
  
  
  return (
    <React.Fragment>
      <div className={classes.root}>
        {isAuthenticated && (
          <AppBar position="fixed" className={classes.header}>
              <Toolbar className={classes.toolbar} >
                  <Logo />
                  <Grid container justifyContent="flex-start" alignItems="center" spacing={5}>
                      <Grid item className="d-none d-md-block">
                          <Typography variant="h6" className={classes.title}>
                              FRONTSTEPS Payments
                          </Typography>
                          <Typography variant="caption" display="block" className={`${classes.caption} text-white`} gutterBottom>
                            {userInfo.userCompany ? userInfo.userCompany.name : userInfo.name}
                          </Typography>
                      </Grid>
                      <Grid item xs={2} sm={4} lg={3} className={classes.menuContainer}>
                          <Button
                            size="large"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={(event:any) => setAnchorEl(event.currentTarget)}
                            className={classes.menuButton}
                            endIcon={
                              Boolean(anchorEl) ? <ExpandLessIcon  /> :
                                <ExpandMoreIcon  />
                            }
                          >
                            { menu.map((item: any) => {
                                if(location.pathname.indexOf(item.path) >= 0) {
                                  return (<span key={item.path}> {item.label} </span>)
                                }
                                return null;
                              })
                            }
                            {
                              location.pathname.indexOf('FAQ') >= 0 && (<span key={'FAQs'}> FAQ's </span>)
                            }
                          </Button>
                          <CustomMenu
                            id="simple-menu"
                            keepMounted
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                          >
                            {
                              menu.map((item: any) => {
                               return <MenuItem key={item.path} className={classes.menuSelect} onClick={() => onChangeMenuItem(item.path)}> {item.label} </MenuItem>
                              })
                            }
                          </CustomMenu>
                      </Grid>
                      <Grid item className="ms-auto">
                        <Grid container>
                          <Grid item>
                            <Button
                              size="large"
                              aria-controls="simple-menu"
                              aria-haspopup="true"
                              onClick={(event:any) => setHelpEL(event.currentTarget)}
                              className={classes.menuButton}
                              endIcon={
                                Boolean(helpEL) ? <ExpandLessIcon  /> :
                                  <ExpandMoreIcon  />
                              }
                            >
                              <HelpOutlineIcon style={{marginTop: 4}} fontSize="large" />
                            </Button>
                            <CustomMenu
                              keepMounted
                              anchorEl={helpEL}
                              open={Boolean(helpEL)}
                              onClose={() => setHelpEL(null)}
                            >
                              <DropdownItem className={classes.menuSelect} tag="a" href="/FAQ" target="_blank">FAQs</DropdownItem>
                            </CustomMenu>
                          </Grid>
                          <Grid item>
                            <IconButton
                              onClick={() => logout({returnTo: config.redirectUri})}
                              aria-label="account of current user"
                              aria-controls="menu-appbar"
                              aria-haspopup="true"
                              color="inherit"
                            >
                              <PowerSettingsNewIcon fontSize="large" />
                            </IconButton>
                          </Grid>
                        </Grid>
                        
                      </Grid>
                  </Grid>
              </Toolbar>
          </AppBar>)}
      </div>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        onClose={handleClose}
      >
        <DialogContent className="modal-content">
          <Grid container className="app-container py-1" spacing={2}>
            <Grid item className="ms-auto">
              <div>
                <IconButton aria-label="account of user" onClick={handleClose}>
                  <CloseIcon fontSize="large" />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

const mapStateToProps = ({userInfo, router}: ApplicationState) => ({
  userInfo,
  location: router.location
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    clearFiltersData : () => {
      switch (window.location.pathname) {
        case '/Payments':
          dispatch(transactionsActions.updateFilter({
            selectedStartDate: moment().startOf('month').toDate(),
            selectedEndDate: moment().endOf('day').toDate(),
            pageNumber: 1,
            pageSize: config.paymentTransactionsPageSize
          }))
          break;
        case '/Homeowners':
          dispatch(homeownersActions.updateFilter({
            pageNumber: 1,
            pageSize: config.homeownersPageSize
          }));
          break;
        case '/Payouts':
          break;
        case '/LockboxFiles':
          break;
        case '/DashboardUser':
          dispatch(updateDashboardUserFilter({
            pageNumber: 1,
            pageSize: config.homeownersPageSize
          }, true));
          break;
        case '/Onboarding':
          break;
        case '/TaxForms':
          break
        case '/Reports':
          break;
          
      }
    }
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type NavMenuProps = ConnectedProps<typeof connector>

export default connector( NavMenu )
