import React from 'react';
import { Slate, Editable, withReact } from 'slate-react';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import {Box} from '@material-ui/core';
import Leaf from "./Leaf";
import Element from "./Element";

interface DisplayTextProps {
  value: any;
}

const DisplayText: React.FC<DisplayTextProps> = (props: DisplayTextProps) => {
  const editor = React.useMemo(() => withHistory(withReact(createEditor())), []);
  const { value } = props;
  
  return (
    <Slate editor={editor} initialValue={value}>
      <Box>
        <Editable
          readOnly
          renderLeaf={(props) => <Leaf {...props} />}
          renderElement={(props) => <Element {...props} />}
          style={{ padding: 8 }}
        />
      </Box>
    </Slate>
  );
};

export default DisplayText;
