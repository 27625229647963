import {config} from "../config";
import httpService from "./HttpService";
import GlobalControlsInterface from "../interfaces/global.controls.interface";
import {ReleaseVersionInterface} from "../interfaces/release.version.interface";

const GLOBAL_CONTROLS_PATH = `${config.baseUrl}${config.suffixApi}${config.versionApi}/reporting/globalControls`
const RELEASE_NOTES_PATH = `${config.baseUrl}${config.suffixApi}${config.versionApi}/reporting/release-notes`
const ACK_RELEASE_NOTES_PATH = `${config.baseUrl}${config.suffixApi}${config.versionApi}/reporting/ack-release-notes`

export const getGlobalControls = ():Promise<GlobalControlsInterface> => {
  return httpService.get<GlobalControlsInterface>(GLOBAL_CONTROLS_PATH)
}

export const getReleaseNotes = ():Promise<ReleaseVersionInterface[]> => {
  return httpService.get<ReleaseVersionInterface[]>(RELEASE_NOTES_PATH)
}

export const createReleaseNotes = (releaseVersion: ReleaseVersionInterface):Promise<void> => {
  return httpService.post<void>(RELEASE_NOTES_PATH, releaseVersion);
}

export const publishReleaseNotes = (versionId: string):Promise<void> => {
  return httpService.put<void>(RELEASE_NOTES_PATH, `"${versionId}"`);
}

export const ackReleaseNotes = (versionId: string):Promise<void> => {
  return httpService.post<void>(ACK_RELEASE_NOTES_PATH, `"${versionId}"`);
}
