import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
  },
  heading: {
    fontSize: "4rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  subheading: {
    fontSize: "1.5rem",
    marginBottom: theme.spacing(4),
    color: theme.palette.text.secondary,
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const NotFound = () => {
  const classes = useStyles();
  
  return (
    <Container className={classes.root}>
      <Typography className={classes.heading} color="primary">
        404
      </Typography>
      <Typography className={classes.subheading}>
        Oops! The page you are looking for doesn’t exist.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        href="/"
      >
        Go Back to Home
      </Button>
    </Container>
  );
};

export default NotFound;
