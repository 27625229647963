export interface ReleaseVersionInterface {
  releaseVersion: string;
  releaseDate: string;
  releaseNotes: string;
  dataType: DataType;
  status: ReleaseStatus;
}

export enum ReleaseStatus {
  Pending = 'Pending',
  Released = 'Released',
}

export enum DataType {
  Html = 'Html',
  Json = 'Json',
}
