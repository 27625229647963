import {
  CLOSE_RELEASE_NOTES_MODAL,
  RECEIVE_GLOBAL_CONTROLS,
  RECEIVE_USER_COMPANY,
  RECEIVE_USER_INFO
} from "../constants/userInfo";
import {UserInfoAction} from "../actions/user.info.action";
import {UserInterface} from "../interfaces/user.interface";

export const userInfoReducer = (store: UserInterface = {isFSEmployee : false}, action: UserInfoAction): UserInterface => {
  switch (action.type) {
    case RECEIVE_USER_INFO:
      return {
        ...store,
        ...action.userInfo,
        isOnboardUser: false,
        fullAccessProfile: false,
        isReportsUser: false,
        isDashboardUser: false,
      }
    case RECEIVE_USER_COMPANY:
      return {
        ...store,
        ...action.userInfo,
        isOnboardUser: action.userInfo.isOnboardUser,
        fullAccessProfile: action.userInfo.fullAccessProfile,
        isReportsUser: action.userInfo.isReportsUser,
        globalReportsEnabled: action.userInfo.globalReportsEnabled,
        isFSEmployee: action.userInfo.isFSEmployee,
        isDashboardUser: action.userInfo.isDashboardUser,
        userCompany: {
          ...store.userCompany,
          ...action.userCompany
        }
      }
    case RECEIVE_GLOBAL_CONTROLS:
      return {
        ...store,
        globalReportsEnabled: action.globalControls.reportsEnabled
      }
    case CLOSE_RELEASE_NOTES_MODAL:
      return {
        ...store,
        releaseModalOpen: false
      }
    default:
      return store
  
  }
}
