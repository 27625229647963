import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper} from "@material-ui/core";
import DisplayText from "../RichTextEditor/DisplayText";
import React from "react";
import {ApplicationState} from "../../store";
import {connect, ConnectedProps} from "react-redux";
import {Dispatch} from "redux";
import {closeReleaseNotesModal} from "../../actions/userInfo";
import {FormGroup, Input, Label} from "reactstrap";
import {DataType} from "../../interfaces/release.version.interface";


const ReleaseNotes: React.FC<ReleaseNotesProps> = (props: ReleaseNotesProps) => {
  const { releaseModalOpen, releaseVersion } = props.userInfo;
  const [skipReleaseNotification, setSkipReleaseNotification] = React.useState(false);
  
  const handleChangeReleaseNotification = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSkipReleaseNotification(event.target.checked);
  }
  
  const handleClose = () => {
    props.closeReleaseNotesModal(skipReleaseNotification);
  }
  
  return (
    <Dialog open={releaseModalOpen ?? false} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Latest Updates!!</DialogTitle>
      <DialogContent dividers>
        {/* DisplayText component to render the release notes */
          releaseVersion?.dataType === DataType.Html ? (
            <Paper
              style={{
                padding: 16,
                border: '1px solid #ccc',
                maxHeight: '420px',
                overflow: 'auto',
              }}
              dangerouslySetInnerHTML={{ __html: JSON.parse(releaseVersion.releaseNotes) }}
            />
          ) : (releaseVersion ? <DisplayText value={JSON.parse(releaseVersion.releaseNotes)} /> : '')
        }
      </DialogContent>
      <DialogActions>
        <FormGroup check style={{position: 'absolute', left: '15px'}}>
          <Input
            type={'checkbox'}
            checked={skipReleaseNotification}
            onChange={handleChangeReleaseNotification}
            name={"skipReleaseNotification"}
            id={"skipReleaseNotification"}
            style={{width: '20px', height: '20px', marginRight: '5px'}}
          />
          <Label
            check
            for={"skipReleaseNotification"}
            style={{ marginTop: '3px'}}
          >
            Please don't show again until next release.
          </Label>
        </FormGroup>
        <Button onClick={handleClose} color="primary" variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = ({userInfo}: ApplicationState) => ({
  userInfo,
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    closeReleaseNotesModal : (skipReleaseNotification: boolean) => {
      dispatch(closeReleaseNotesModal(skipReleaseNotification));
    }
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ReleaseNotesProps = ConnectedProps<typeof connector>

export default connector( ReleaseNotes )
